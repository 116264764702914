export default function convertJobType(jobType) {
  switch (jobType) {
    case 'public':
      return 'Emploi public'
    case 'cdi':
    case 'mandate':
      return 'CDI'
    case 'cdd':
    case 'cdd-public':
      return 'CDD'
    case 'internship':
      return 'Stage / Alternance'
    case 'volunteering':
      return 'Volontariat / Service civique'
    default:
      return jobType
  }
}
