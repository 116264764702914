export default function () {
  const config = useRuntimeConfig()
  const client = useStrapiClient()
  const strapiUser = useStrapiUser()
  const token = useStrapiToken()

  const user = computed(() => {
    const data = strapiUser.value
    if (!data) return null

    const employment = data?.employments?.[0]

    return {
      ...data,
      employment,
      isEmployer: !!employment,
      isSupervisor: employment?.id === 0,
      hasApplied(jobId) {
        return !!this.candidate?.applications?.some(app => jobId === app.job?.id)
      },
      hasRole(...roles) {
        return roles.includes(this.employment?.role)
      }
    }
  })

  const fetchUser = async () => {
    try {
      const data = await client('/users/me', {
        params: config.public.strapi.auth
      })
      strapiUser.value = data
    } catch (error) {
      console.error(error)
    }
  }

  return { fetchUser, token, user }
}
