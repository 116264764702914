<script setup>
  import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
  import { notify } from 'notiwind'
  import { handleError } from '~/utils/error'
  import getFullName from '~/utils/get-full-name'

  const ats = useAts()
  const slidingMenu = useHeaderSlidingMenu()
  const auth = useStrapiAuth()
  const client = useStrapiClient()
  const top = useTop()
  const { fetchUser, user } = useUser()

  async function onLogout() {
    try {
      await navigateTo('/')
      await sleep(250)
      await client('/auth/logout', { method: 'POST' })
      auth.logout()
    } catch (error) {
      handleError(error)
    }
  }

  async function setActiveEmployment(employmentId) {
    try {
      if (employmentId > 0) {
        await client(`/users/active-employer/${employmentId}`, { method: 'PUT' })
        await fetchUser()
      }
      await navigateTo('/ats')
    } catch (error) {
      handleError(error)
    }
  }
</script>

<template>
  <header class="fixed inset-x-0 z-50">
    <LayoutsDefaultHeaderSlidingMenu />

    <LayoutsDefaultHeaderDevMode />

    <div class="relative shadow" :class="[!ats.isAts ? 'bg-white' : 'bg-tarawera-900 text-white']">
      <LoadingBar />

      <div class="relative mx-auto flex h-16 max-w-screen-xl items-center px-4 duration-500" :class="[top ? 'md:h-20' : 'md:h-16']">
        <NuxtLink
          :to="ats.isAts ? '/ats' : '/'"
          :class="[
            'mr-4 mt-1 shrink-0 self-start rounded-full outline-none duration-500 focus-visible:ring-2 focus-visible:ring-viking-400/50 lg:mr-8',
            top ? 'md:mt-2' : 'md:mt-1'
          ]"
        >
          <div
            v-if="ats.isAts && ats.employer.logo?.url"
            :class="[
              'aspect-square h-20 w-20 rounded-full bg-zinc-300 bg-cover bg-center shadow transition-[height,width] duration-500',
              top ? 'md:h-24 md:w-24' : 'md:h-20 md:w-20'
            ]"
            :style="{
              backgroundImage: `url('${useStrapiMedia(ats.employer.logo.url)}')`
            }"
          />
          <NuxtImg
            v-else
            alt="Logo Profil Public"
            src="/images/logo-profil-public-600x600.webp"
            :class="['aspect-square h-20 w-20 rounded-full duration-500', top ? 'md:h-24 md:w-24' : 'md:h-20 md:w-20']"
          />
        </NuxtLink>

        <div class="mr-4 hidden grow whitespace-nowrap md:flex md:items-center lg:mr-8">
          <template v-if="!ats.isAts">
            <div class="mr-4 lg:mr-8">
              <NuxtLink
                class="font-special font-bold outline-none duration-300 hover:text-viking-500 focus-visible:text-viking-500"
                to="/nos-services"
                >Nos services</NuxtLink
              >
            </div>

            <div class="ml-auto flex items-center space-x-4 lg:space-x-8">
              <div>
                <Tooltip content="Trouvez un job dans le secteur public">
                  <NuxtLink class="font-special font-bold outline-none duration-300 hover:text-viking-500 focus-visible:text-viking-500" to="/jobs">
                    <span class="hidden lg:inline">Les jobs</span><span class="inline lg:hidden">Jobs</span>
                  </NuxtLink>
                </Tooltip>
              </div>

              <div>
                <Tooltip content="Entrez dans les coulisses des institutions">
                  <NuxtLink
                    class="font-special font-bold outline-none duration-300 hover:text-viking-500 focus-visible:text-viking-500"
                    to="/employeurs"
                  >
                    <span class="hidden lg:inline">Les employeurs</span><span class="inline lg:hidden">Employeurs</span>
                  </NuxtLink>
                </Tooltip>
              </div>

              <div>
                <Tooltip content="Explorez le secteur public">
                  <NuxtLink
                    class="font-special font-bold outline-none duration-300 hover:text-viking-500 focus-visible:text-viking-500"
                    target="_blank"
                    to="https://media.profilpublic.fr/je-minspire"
                  >
                    <span class="hidden lg:inline">Le média</span><span class="inline lg:hidden">Média</span>
                  </NuxtLink>
                </Tooltip>
              </div>

              <div class="h-6 border-l border-zinc-300" />

              <NuxtLink
                class="font-special font-bold outline-none duration-300 hover:text-viking-500 focus-visible:text-viking-500"
                to="/employeurs/profil-public"
                >Qui sommes-nous ?</NuxtLink
              >

              <NuxtLink
                class="group rounded-lg border px-2 py-1 font-special font-bold duration-300 hover:bg-tarawera-800 hover:text-white focus-visible:text-viking-500"
                v-if="user && !user.isEmployer"
                target="_blank"
                to="https://media.profilpublic.fr/wp-content/uploads/2024/10/Guide-candidat-Profil-Public.pdf"
              >
                <div class="mr-1 hidden w-4 group-hover:inline-block"><Icon name="book-open-cover" type="bold" /></div>
                <div class="mr-1 inline-block w-4 group-hover:hidden"><Icon name="book" type="bold" /></div>
                Guide Candidat
              </NuxtLink>
            </div>
          </template>
          <div class="ml-auto flex items-center space-x-4 font-bold lg:space-x-8" v-else>
            <NuxtLink class="font-special outline-none duration-300 hover:text-viking-500 focus-visible:text-viking-500" to="/ats/offres"
              >Offres</NuxtLink
            >

            <NuxtLink
              class="font-special outline-none duration-300 hover:text-viking-500 focus-visible:text-viking-500"
              v-if="ats.isSubscribed('ATS')"
              to="/ats/candidats"
              >CVthèque</NuxtLink
            >

            <NuxtLink
              class="font-special outline-none duration-300 hover:text-viking-500 focus-visible:text-viking-500"
              v-if="ats.isSubscribed('ATS')"
              to="/ats/statistiques"
              >Statistiques</NuxtLink
            >

            <div>
              <NuxtLink
                class="group rounded-lg border px-2 py-1 font-special duration-300 hover:border-viking-500 hover:bg-tarawera-800 focus-visible:text-viking-500"
                target="_blank"
                to="https://classic-weather-bc0.notion.site/Votre-guide-employeur-Profil-Public-74438abc81394e779087141833f28b30"
              >
                <div class="mr-1 hidden w-4 group-hover:inline-block"><Icon name="book-open-cover" type="regular" /></div>
                <div class="mr-1 inline-block w-4 group-hover:hidden"><Icon name="book" type="regular" /></div>
                Conseils RH
              </NuxtLink>
            </div>

            <div v-if="user?.hasRole('admin')">
              <Tooltip content="Paramètres">
                <NuxtLink
                  class="font-special outline-none duration-300 hover:text-viking-500 focus-visible:text-viking-500"
                  to="/ats/employeur/collaborateurs"
                >
                  <Icon fixed-width name="gear" size="lg" type="solid" />
                </NuxtLink>
              </Tooltip>
            </div>

            <NotificationsMenu />
          </div>
        </div>

        <Menu class="relative ml-auto flex items-center" v-slot="{ close }" as="div">
          <NotificationsMenu class="mr-4 font-bold md:hidden" v-if="ats.isAts" />

          <MenuButton
            class="block rounded-full text-viking-400 outline-none duration-300 hover:text-viking-500 focus-visible:ring-2 focus-visible:ring-viking-400/50"
            aria-label="User Menu"
          >
            <Icon v-if="!user" name="circle-user" size="2xl" />
            <Avatar class="aspect-square h-8 w-8" v-else :user="user" />
          </MenuButton>
          <transition
            enter-active-class="duration-300"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="duration-300"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <MenuItems
              class="absolute right-0 top-8 mt-1 w-64 rounded border border-zinc-300 bg-white text-tarawera-900 shadow outline-none focus-visible:ring-2 focus-visible:ring-zinc-300/50"
            >
              <div class="flex items-center p-3" v-if="!user">
                <MenuItem v-slot="{ active }">
                  <button
                    @click="navigateTo('#connexion')"
                    :class="[
                      'group flex flex-1 flex-col items-center space-y-2 p-1 outline-none duration-300 hover:text-viking-500',
                      { 'text-viking-500': active }
                    ]"
                  >
                    <Icon
                      fixed-Width
                      name="power-off"
                      size="xl"
                      :class="['text-viking-400 duration-300 group-hover:text-viking-500', { 'text-viking-500': active }]"
                    />
                    <div>Connexion</div>
                  </button>
                </MenuItem>

                <div class="mx-3 my-1 h-12 border-l border-zinc-300" />

                <MenuItem v-slot="{ active }">
                  <button
                    @click="navigateTo('#inscription')"
                    :class="[
                      'group flex flex-1 flex-col items-center space-y-2 p-1 outline-none duration-300 hover:text-viking-500',
                      { 'text-viking-500': active }
                    ]"
                  >
                    <Icon
                      fixed-Width
                      name="pen-field"
                      size="xl"
                      type=""
                      :class="['text-viking-400 duration-300 group-hover:text-viking-500', { 'text-viking-500': active }]"
                    />
                    <div>Inscription</div>
                  </button>
                </MenuItem>
              </div>

              <div class="flex flex-col justify-stretch p-3" v-else>
                <NuxtLink
                  class="group flex items-center space-x-2 p-1 outline-none"
                  :to="user.isEmployer ? '/mon-profil/informations' : '/mon-profil'"
                  @click="close"
                >
                  <Avatar class="aspect-square h-14 w-14 text-3xl" :user="user" />
                  <div class="grow overflow-hidden">
                    <div class="truncate font-special font-bold">{{ getFullName(user) }}</div>
                    <div class="mt-1">
                      <span
                        class="mt-1 flex font-special text-xs font-bold underline decoration-viking-400 decoration-2 underline-offset-2 duration-300 group-hover:text-viking-500"
                        >Gérer mon profil</span
                      >
                    </div>
                  </div>
                </NuxtLink>

                <template v-if="user.isEmployer">
                  <hr class="mx-1 my-3 border-zinc-100" />

                  <LayoutsDefaultHeaderSupervisor v-if="user.isSupervisor" :employer="ats.employer" />

                  <template v-else v-for="(employment, index) in user.employments" :key="index">
                    <MenuItem v-if="employment.employer">
                      <button
                        @click="setActiveEmployment(employment.id)"
                        :class="[
                          'flex w-full items-center p-1 outline-none duration-300 hover:text-viking-500',
                          { 'text-viking-500': employment.active }
                        ]"
                      >
                        <div
                          class="mr-2 aspect-square h-6 w-6 rounded-full bg-zinc-300 bg-cover bg-center shadow"
                          v-if="employment.employer?.logo?.url"
                          :style="{
                            backgroundImage: `url('${useStrapiMedia(employment.employer.logo.url)}')`
                          }"
                        />
                        <div class="mr-auto truncate">{{ employment.employer?.name }}</div>
                        <Icon class="ml-2 text-green-600" v-if="employment.active" name="check" />
                      </button>
                    </MenuItem>
                  </template>
                </template>

                <div v-if="user.candidate">
                  <hr class="mx-1 my-3 border-zinc-100" />
                  <NuxtLink class="flex w-full p-1 outline-none" to="/mon-profil/candidatures" @click="close">
                    <span class="font-special text-sm font-bold duration-300 hover:text-viking-500">Candidatures</span>
                  </NuxtLink>
                  <NuxtLink class="mt-1 flex w-full p-1 outline-none" to="/mon-profil/alertes-emploi" @click="close">
                    <span class="font-special text-sm font-bold duration-300 hover:text-viking-500">Alertes emploi</span>
                  </NuxtLink>
                  <NuxtLink class="mt-1 flex w-full p-1 outline-none" to="/mon-profil/favoris" @click="close">
                    <span class="font-special text-sm font-bold duration-300 hover:text-viking-500">Favoris</span>
                  </NuxtLink>
                </div>

                <hr class="mx-1 my-3 border-zinc-100" />
                <MenuItem v-slot="{ active }">
                  <button
                    @click="onLogout"
                    :class="[
                      'group flex items-center space-x-2 p-1 text-left outline-none duration-300 hover:text-viking-500',
                      { 'text-viking-500': active }
                    ]"
                  >
                    <Icon
                      fixed-width
                      name="power-off"
                      :class="['text-viking-400 duration-300 group-hover:text-viking-500', { 'text-viking-500': active }]"
                    />
                    <div class="truncate">Déconnexion</div>
                  </button>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>

        <button
          class="ml-4 aspect-square h-8 w-8 rounded-full text-viking-400 outline-none duration-300 hover:text-viking-500 focus-visible:ring-2 focus-visible:ring-viking-400/50 md:!hidden"
          @click="slidingMenu = !slidingMenu"
        >
          <Icon name="bars" size="xl" />
        </button>
      </div>
    </div>
  </header>
</template>
