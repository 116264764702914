export default function (to) {
  const route = to || useRoute()
  const { user } = useUser()
  const dayjs = useDayjs()

  const ats = computed(() => {
    const currentUser = user.value
    const employment = currentUser?.employment || null
    const employer = employment?.employer || null
    const isAts = (!!route.name?.startsWith('ats') || !!route.name?.startsWith('mon-compte')) && !!employment

    const isSubscriptionCurrent = () => {
      if (!employer) return false
      const today = dayjs().format('YYYY-MM-DD')
      return (!employer.subscriptionStart || employer.subscriptionStart <= today) && employer.subscriptionEnd >= today
    }

    return {
      employer,
      employment,
      isAts,
      hasEnoughCredits() {
        return employer?.subscriptionCredits > 0 || employer?.subscriptionType === 'ILLIMITE'
      },
      hasRole(roles) {
        roles = !Array.isArray(roles) ? roles.replace(/ /i, '').split(',') : roles
        return roles.includes(employment?.role)
      },
      isSubscribed(types = [], checkDate = false) {
        if (!employer?.subscriptionType) return false
        if (checkDate && !isSubscriptionCurrent()) return false
        types = !Array.isArray(types) ? types.replace(/ /i, '').split(',') : types
        return !types.length || types.includes(employer?.subscriptionType)
      },
      isSubscriptionCurrent
    }
  })

  return ats
}
