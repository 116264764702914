<script setup>
  import { Combobox, ComboboxButton, ComboboxInput, ComboboxOptions, ComboboxOption } from '@headlessui/vue'

  const props = defineProps({
    employer: { type: Object }
  })

  const { find } = useStrapi4()
  const { user } = useUser()

  const selectedEmployer = ref(props.employer)
  const query = ref('')
  const filteredEmployers = computed(() => {
    if (!employers.data) return []
    return query.value === ''
      ? employers.data
      : employers.data.filter(employer => {
          return employer.name.toLowerCase().includes(query.value.toLowerCase())
        })
  })

  const employers = reactive({
    ...(await useLazyAsyncData(
      'supervisor-employers',
      async () => {
        const res = await find('employers', {
          fields: [
            'name',
            'slug',
            'subscriptionCredits',
            'subscriptionEnd',
            'subscriptionQuota',
            'subscriptionStart',
            'subscriptionType',
            'supportEmail'
          ],
          filters: {
            subscriptionType: { $not: null }
          },
          pagination: { limit: 250, start: 0 },
          populate: {
            logo: { fields: ['url'] }
          }
        })
        return res.data
      },
      {
        server: false
      }
    ))
  })

  watch(selectedEmployer, async employer => {
    user.value.employments[0].employer = employer
    await navigateTo('/ats')
  })
</script>

<template>
  <p class="mb-1 pl-1 text-sm text-zinc-400">Vous êtes collaborateur fantôme</p>

  <Loading v-if="employers.loading" />
  <Combobox class="relative w-full" v-else v-model="selectedEmployer" as="div">
    <ComboboxInput class="form-input w-full" @change="query = $event.target.value" :displayValue="employer => employer.name" />
    <ComboboxButton class="form-select absolute right-1 top-1 h-5/6 w-9 items-center border-0"></ComboboxButton>
    <ComboboxOptions
      class="absolute z-10 mt-0.5 max-h-64 w-full max-w-full overflow-auto rounded border border-zinc-300 bg-white shadow focus:border-viking-400 focus:outline-none focus:ring-1 focus:ring-viking-400"
    >
      <ComboboxOption
        class="flex cursor-default items-center truncate px-2 py-1.5 text-sm hover:bg-zinc-100"
        v-for="employer in filteredEmployers"
        :key="employer.id"
        :value="employer"
      >
        <div
          class="mr-2 aspect-square h-5 w-5 rounded-full bg-zinc-300 bg-cover bg-center shadow"
          v-if="employer.logo?.url"
          :style="{
            backgroundImage: `url('${useStrapiMedia(employer.logo.url)}')`
          }"
        />
        <div class="mr-auto truncate">{{ employer.name }}</div>
      </ComboboxOption>
    </ComboboxOptions>
  </Combobox>
</template>
