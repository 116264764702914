<script setup>
  const { query } = useRoute()
  const { find } = useStrapi4()

  const employers = reactive({
    ...useLazyAsyncData(
      'home-employers',
      async () => {
        const res = await find('employers', {
          fields: ['name', 'slug'],
          filters: { visible: true },
          pagination: { limit: 10, start: 0, withCount: false },
          populate: {
            careerSite: { fields: ['active', 'slug'] },
            cover: { fields: ['url'] },
            logo: { fields: ['url'] }
          },
          sort: 'random'
        })
        return res.data
      },
      { default: () => [] }
    )
  })

  const jobs = reactive({
    ...useLazyAsyncData(
      'home-jobs',
      async () => {
        const res = await find('jobs', {
          fields: ['moderated', 'slug', 'title', 'type', 'validatedAt'],
          filters: {
            moderated: true,
            published: true
          },
          pagination: { limit: 3, start: 0, withCount: false },
          populate: {
            employer: {
              fields: ['name'],
              populate: {
                cover: { fields: ['url'] },
                logo: { fields: ['url'] }
              }
            },
            header: {
              fields: ['title'],
              populate: { cover: { fields: ['url'] } }
            },
            locations: {
              fields: ['*'],
              populate: {
                region: { fields: ['name', 'slug'] }
              }
            },
            speaker: {
              fields: ['firstName', 'lastName'],
              populate: { photo: { fields: ['url'] } }
            }
          },
          sort: { validatedAt: 'desc' }
        })
        return res.data
      },
      { default: () => [] }
    )
  })

  const articles = reactive({
    ...useLazyAsyncData(
      'home-articles',
      async () => {
        const res = await find('articles', {
          fields: ['title', 'slug'],
          pagination: { limit: 10, start: 0, withCount: false },
          populate: { image: { fields: ['url'] } },
          sort: ['featured:desc', 'publishedAt:desc']
        })
        if (res.data.length >= 2) {
          const tmp = res.data[0]
          res.data[0] = res.data[1]
          res.data[1] = tmp
        }
        return res.data
      },
      { default: () => [] }
    )
  })

  const scrollBottom = () => {
    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: document.body.scrollHeight
    })
  }

  const regions = reactive({
    ...(await useLazyAsyncData(
      'regions',
      async () => {
        const res = await find('regions', {
          fields: ['name', 'slug'],
          pagination: { limit: 100, start: 0, withCount: false },
          sort: { name: 'asc' }
        })
        return res.data
      },
      {
        default: () => []
      }
    ))
  })

  const filters = reactive({
    region: query.localisation?.length ? regions.data.find(({ slug }) => query.localisation.split(',').includes(slug)) : undefined,
    search: query.recherche?.length ? query.recherche : '',
    sector: [],
    teleworkable: false,
    type: false,
    management: false,
    employerType: []
  })
  const sectors = reactive({
    ...(await useLazyAsyncData(
      'sectors',
      async () => {
        const res = await find('sectors', {
          fields: ['name', 'slug'],
          pagination: { limit: 100, start: 0, withCount: false },
          sort: { name: 'asc' }
        })
        return res.data
      },
      {
        default: () => []
      }
    ))
  })

  const createJobSearchUrl = () => {
    const suffix = '/jobs'
    const params = []
    if (filters.region) params.push(`localisation=${filters.region.slug}`)
    if (filters.search.length) params.push(`recherche=${filters.search}`)
    if (filters.sector.length) params.push(`secteur=${filters.sector.map(sector => encodeURIComponent(sector.slug)).join(',')}`)
    return suffix + (params.length ? '?' + params.join('&') : '')
  }
</script>

<template>
  <Page code="home">
    <section>
      <JobSearchForm
        class="bg-zinc-100"
        :sticky="false"
        :advancedTab="false"
        :sectors="sectors"
        :filters="filters"
        :regions="regions"
        :createJobSearchUrl="createJobSearchUrl"
      >
        <template v-slot:title>
          <h1 class="font-special text-2xl font-bold md:text-3xl lg:text-4xl">Trouvez un job <br class="max-md:hidden" />dans le secteur public</h1>
        </template>

        <template v-slot:cta>
          <NuxtLink :to="createJobSearchUrl()">
            <Button class="absolute -bottom-3 left-1/2 -translate-x-1/2 whitespace-nowrap" size="sm">
              <Icon name="magnifying-glass" type="regular" />
              <span class="ml-2">Voir les jobs</span>
            </Button>
          </NuxtLink>
        </template>
      </JobSearchForm>
    </section>

    <section class="mx-auto max-w-screen-xl space-y-8 px-4 py-16">
      <div>
        <div class="space-y-4 sm:flex sm:items-center sm:space-x-8 sm:space-y-0">
          <NuxtLink
            class="font-special text-3xl font-bold uppercase underline decoration-viking-400 decoration-4 underline-offset-8 duration-300 hover:text-viking-500"
            to="/employeurs"
            >Les employeurs</NuxtLink
          >
          <div class="text-lg">Entrez dans les coulisses des institutions</div>
        </div>
        <div class="mt-4">
          <Loading v-if="employers.pending" />
          <Error v-else-if="employers.error" :retry="employers.refresh" />
          <Swiper class="-mx-4" v-else>
            <div class="max-w-full snap-center px-4 sm:snap-align-none" v-for="employer in employers.data">
              <CardEmployer class="my-1.5 w-80 max-w-full" :employer="employer" />
            </div>
          </Swiper>
        </div>
        <div class="mt-2 text-right">
          <NuxtLink
            class="font-special font-bold underline decoration-viking-400 decoration-2 underline-offset-4 duration-300 hover:text-viking-500"
            to="/employeurs"
            >Voir tout</NuxtLink
          >
        </div>
      </div>

      <div>
        <div class="space-y-4 sm:flex sm:items-center sm:space-x-8 sm:space-y-0">
          <NuxtLink
            class="font-special text-3xl font-bold uppercase underline decoration-viking-400 decoration-4 underline-offset-8 duration-300 hover:text-viking-500"
            to="/jobs"
            >Les jobs</NuxtLink
          >
          <div class="text-lg">Découvrez nos dernières offres en ligne</div>
        </div>
        <div class="mt-4">
          <Loading v-if="jobs.pending" />
          <Error v-else-if="jobs.error" :retry="jobs.refresh" />
          <div class="max-w-full py-4" v-else v-for="job in jobs.data">
            <BigCardJob class="" :job="job" />
          </div>
        </div>
        <div class="mt-2 text-right">
          <NuxtLink
            class="font-special font-bold underline decoration-viking-400 decoration-2 underline-offset-4 duration-300 hover:text-viking-500"
            to="/jobs"
            >Voir tout</NuxtLink
          >
        </div>
      </div>
    </section>

    <section class="bg-zinc-100">
      <Quote class="mx-auto max-w-screen-md px-4 py-8 text-lg">
        J’ai créé la plateforme Profil Public pour réenchanter le recrutement du secteur public. Découvrez
        <NuxtLink
          class="text-viking-400 duration-300 hover:text-viking-500"
          target="_blank"
          to="https://profilpublic.fr/articles/?cat=temoignages-metiers"
          >ici</NuxtLink
        >
        des témoignages métiers d’acteurs publics innovants, plongez dans les coulisses des organisations et trouvez le job qui vous correspond au
        service de l’intérêt général.<br />
        Suivez-nous sur
        <NuxtLink class="text-viking-400 duration-300 hover:text-viking-500" target="_blank" to="https://www.linkedin.com/company/profilpublic"
          >LinkedIn</NuxtLink
        >
        et via notre <a class="text-viking-400 duration-300 hover:text-viking-500" href="" @click.prevent="scrollBottom">newsletter</a>.
        <template #profile>
          <Profile
            :profile="{
              linkedin: 'sigrid-berger',
              name: 'Sigrid Berger',
              photo: '/images/sigrid-150x150.png',
              title: 'Fondatrice de Profil Public & administratrice territoriale'
            }"
            small
            row
          />
        </template>
      </Quote>
    </section>

    <section class="mx-auto max-w-screen-xl px-4 py-16">
      <div class="space-y-4 sm:flex sm:items-center sm:space-x-8 sm:space-y-0">
        <NuxtLink
          class="font-special text-3xl font-bold uppercase underline decoration-viking-400 decoration-4 underline-offset-8 duration-300 hover:text-viking-500"
          target="_blank"
          to="https://profilpublic.fr/je-minspire"
          >Le média</NuxtLink
        >
        <div class="text-lg">Explorez le secteur public</div>
      </div>
      <div class="mt-4">
        <Loading v-if="articles.pending" />
        <Error v-else-if="articles.error" :retry="articles.refresh" />
        <Swiper class="-mx-4" v-else>
          <div class="flex max-w-full snap-center px-4 sm:snap-align-none" v-for="article in articles.data">
            <CardArticle class="my-1.5 w-80 max-w-full" :article="article" />
          </div>
        </Swiper>
      </div>
      <div class="mt-2 text-right">
        <NuxtLink
          class="font-special font-bold underline decoration-viking-400 decoration-2 underline-offset-4 duration-300 hover:text-viking-500"
          target="_blank"
          to="https://profilpublic.fr/je-minspire"
          >Voir tout</NuxtLink
        >
      </div>
    </section>
  </Page>
</template>
