<script setup>
  const isOpen = useHeaderSlidingMenu()

  const ats = useAts()
  const { user } = useUser()
</script>

<template>
  <div
    @click.self="isOpen = false"
    :class="[
      'fixed bottom-0 left-0 right-0 top-16 bg-black duration-500 md:hidden',
      isOpen ? 'bg-opacity-50 backdrop-blur' : 'pointer-events-none bg-opacity-0'
    ]"
  >
    <div
      :class="[
        'overflow-y- max-h-full px-2 pb-6 pt-10 font-special text-lg font-bold shadow duration-500',
        isOpen ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0',
        !ats.isAts ? 'bg-white' : 'bg-tarawera-900 text-white'
      ]"
    >
      <template v-if="!ats.isAts">
        <NuxtLink class="block p-2 outline-none duration-300 hover:text-viking-500" to="/jobs">
          <Tooltip content="Trouvez un job dans le secteur public" placement="bottom-start">Les jobs</Tooltip>
        </NuxtLink>

        <NuxtLink class="block p-2 outline-none duration-300 hover:text-viking-500" to="/employeurs">
          <Tooltip content="Entrez dans les coulisses des institutions" placement="bottom-start">Les employeurs</Tooltip>
        </NuxtLink>

        <NuxtLink class="block p-2 outline-none duration-300 hover:text-viking-500" target="_blank" to="https://media.profilpublic.fr/je-minspire">
          <Tooltip content="Explorez le secteur public" placement="bottom-start">Le média</Tooltip>
        </NuxtLink>

        <hr class="mx-2 my-4 border-zinc-100" />

        <NuxtLink class="block p-2 outline-none duration-300 hover:text-viking-500" to="/employeurs/profil-public">Qui sommes-nous&nbsp;?</NuxtLink>

        <NuxtLink
          class="block space-x-2 p-2 font-special outline-none duration-300 hover:text-viking-500"
          v-if="user && !user.isEmployer"
          target="_blank"
          to="https://media.profilpublic.fr/wp-content/uploads/2024/10/Guide-candidat-Profil-Public.pdf"
        >
          <Icon fixed-width name="book-open-cover" type="regular" />
          <span>Guide Candidat</span>
        </NuxtLink>
        <NuxtLink class="block p-2 outline-none duration-300 hover:text-viking-500" to="/nos-services">Nos services</NuxtLink>
      </template>

      <template v-else>
        <NuxtLink class="block p-2 font-special outline-none duration-300 hover:text-viking-500" to="/ats">Accueil</NuxtLink>
        <NuxtLink class="block p-2 font-special outline-none duration-300 hover:text-viking-500" to="/ats/offres">Offres</NuxtLink>
        <NuxtLink class="block p-2 font-special outline-none duration-300 hover:text-viking-500" to="/ats/candidats">CVthèque</NuxtLink>
        <NuxtLink class="block p-2 font-special outline-none duration-300 hover:text-viking-500" to="/ats/statistiques">Statistiques</NuxtLink>

        <NuxtLink
          class="block space-x-2 p-2 font-special outline-none duration-300 hover:text-viking-500"
          target="_blank"
          to="https://classic-weather-bc0.notion.site/Votre-guide-employeur-Profil-Public-74438abc81394e779087141833f28b30"
        >
          <Icon fixed-width name="book-open-cover" type="regular" />
          <span>Conseils RH</span>
        </NuxtLink>

        <NuxtLink
          class="block space-x-2 p-2 font-special outline-none duration-300 hover:text-viking-500"
          v-if="user?.hasRole('admin')"
          to="/ats/employeur/collaborateurs"
        >
          <Icon fixed-width name="gear" type="regular" />
          <span>Paramètres</span>
        </NuxtLink>
      </template>
    </div>
  </div>
</template>
